import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ElementRef,
  ViewChild,
  input,
  ChangeDetectionStrategy,
  effect,
  model,
  inject,
  computed,
  signal,
} from '@angular/core';
import { CommonModule, NgStyle, NgTemplateOutlet } from '@angular/common';
import { v4 as uuidV4 } from 'uuid';
import {
  Asset,
  ScheduleDays,
  Maybe,
  AssetItem,
  ImageAsset,
  IFrameAsset,
  Media,
  VideoAsset,
  ResourceType,
  PlaylistType,
  MediaDetailedFragment,
  AssetType,
  CustomFeatures,
} from '@designage/gql';
import {
  getLocalizedTime,
  getDateTime,
  getISOTime,
  getAssetStatus,
  getActivityStatusColor,
  getRandomString,
  getISOTimeNoTz,
  AssetItemIsVideo,
  AssetItemIsInteractiveTargetVideo,
  AssetItemIsImage,
  AssetItemIsImageOrVideo,
  AssetItemToImageOrVideo,
  dragDropCursorUtil,
  getCopyName,
} from '@desquare/utils';
import {
  AspectResizeCropService,
  CurrentUserService,
  MediaService,
  PlaylistViewService,
  PlaylistEditorService,
  SessionService,
  PopupService,
} from '@desquare/services';
import { SubSink } from 'subsink';
import {
  IScheduledDayIndicator,
  IActivityStatus,
  IInteractiveLayoutModalResponse,
} from '@desquare/interfaces';
import {
  ActivityStatusColor,
  ConfirmationResponse,
  MediaSourceTypes,
} from '@desquare/enums';
import { ActivityStatus, CeCalledFrom } from '@desquare/enums';
import {
  NgbModal,
  NgbAccordionModule,
  NgbDropdownModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { AddAssetContentDialogComponent } from '@designage/app/asset/add-asset-content-dialog/add-asset-content-dialog.component';
import { trapLetters as _trapLetters } from '@desquare/utils';
import { cloneDeep } from 'lodash';
import { EditAssetContentDialogComponent } from '@designage/app/asset/edit-asset-content-dialog/edit-asset-content-dialog.component';
import { CreativeEditorComponent } from '@designage/app/creative-editor/creative-editor/creative-editor.component';
import _ from 'lodash';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragExit,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { IframeEditorComponent } from '@designage/app/shared/iframe-editor/iframe-editor.component';
import { InteractiveLayoutManageDialogComponent } from '@designage/app/layout/interactive-layout-manage-dialog/interactive-layout-manage-dialog.component';
import { DurationPipe } from '@desquare/components/common/src/pipe/duration/duration.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { ContentItemComponent } from '@designage/app/playlist-editor/content-item/content-item.component';
import { PlaylistStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [
    NgStyle,
    NgTemplateOutlet,
    DragDropModule,
    TranslateModule,
    DurationPipe,
    DateProxyPipe,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbTooltip,
    ContentItemComponent,
    TimepickerComponent,
  ],
  selector: 'app-sequence',
  templateUrl: './sequence.component.html',
  styleUrls: ['./sequence.component.scss'],
  providers: [MediaService, DateProxyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceComponent implements OnInit, OnDestroy {
  sessionService = inject(SessionService);
  popupService = inject(PopupService);
  playlistStore = inject(PlaylistStore);

  asset = input.required<Asset>();
  @Input() editingAssetIdx = -1;
  @Input() readOnly!: boolean;

  private subs = new SubSink();

  @ViewChild('nameInput') nameInput!: ElementRef;

  @Input() isChild!: boolean;

  simulateDateTime = input.required<Maybe<moment.Moment>>();
  /** this is the SEQUENCE!!~ */
  @Input() simpleUiActive!: boolean;

  currentPlaylistStatus = input.required<ActivityStatus>();

  assetsCount = input.required<number>();

  /** activity status of Asset, Daypart, sequence */
  activityStatus = computed(() => {
    let newStatus = this.currentPlaylistStatus();

    if (this.isDuplicateStartTime) {
      newStatus = ActivityStatus.INACTIVE;
    } else if (this.currentPlaylistStatus() === ActivityStatus.ACTIVE) {
      // supplying currentPlaylistStatus means this component is used by an external parent
      const now =
        this.simulateDateTime() || this.sessionService.currentMoment();
      newStatus =
        getAssetStatus(this.asset(), this.assetsCount(), now.toDate()) ===
        ActivityStatus.ACTIVE
          ? ActivityStatus.ACTIVE
          : ActivityStatus.WAITING;
    }

    const ret: IActivityStatus = {
      status: newStatus,
      style: {
        'background-color': getActivityStatusColor(newStatus),
      },
    };
    return ret;
  });

  get canEdit() {
    return this.editingAssetIdx >= 0 && !this.readOnly;
  }

  playlistType = input.required<PlaylistType>();
  get isInteractive() {
    return this.playlistType() === PlaylistType.Interactive;
  }

  /**
   * gets the asset ids for drag-drop config
   */
  assetIds = computed(() => {
    const assets = this.playlistStore.playlist.assets() || [];
    return assets.map((x) => x.id);
  });

  /**
   * gets the sequence (formerly known as an asset) id
   */
  get sequenceId() {
    return this.asset().id;
  }

  contentStyle = {};
  dateDuration!: string;
  timeDuration!: Maybe<string>;
  defaultScheduleDays!: ScheduleDays;
  scheduleDays!: IScheduledDayIndicator[];

  startTime = computed(() => {
    const time = this.asset().actualStartTime || '00:00:00';
    // console.log('startTime', getDateTime(time));
    return moment(getDateTime(this.asset().actualStartTime || '00:00:00'));
  });
  endTimeView = computed(() => {
    const time = this.asset().actualEndTime || '00:00:00';
    // console.log('endTime', getDateTime(time));
    return moment(getDateTime(time));
  });

  nameValue!: string;
  isEditingName!: boolean;
  isDuplicateStartTime!: boolean;
  trapLetters = _trapLetters;
  profileId!: Maybe<string>;
  isStartTimeInputOpen!: boolean;
  startTimeInputValue!: string;
  totalDuration!: number;
  /** is this used? from playlistEditorService */
  assetItems!: Maybe<AssetItem[]>;
  isDroplistEntered = signal<boolean>(false);

  popLicensed = false;

  constructor(
    private currentUserService: CurrentUserService,
    private playlistEditorService: PlaylistEditorService,
    private modalService: NgbModal,
    private session: SessionService,
    private playlistViewService: PlaylistViewService,
    private mediaService: MediaService,
    private arcService: AspectResizeCropService,
  ) {
    // effect(() => {
    //   console.log('asset', this.asset());
    // });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.profileId = this.session.profileId();
    this.initValues();
    this.initDynamicLabels();
    this.initSubscriptions();
    // console.log('setting Values');

    this.setValues();
  }

  // dynamic labels
  ADD_CONTENT = 'ADD_CONTENT';
  DELETE_SEQUENCE = 'DELETE_SEQUENCE';
  DELETE_SEQUENCE_PROMPT = 'DELETE_SEQUENCE_PROMPT';
  DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_SUCCESS';

  initDynamicLabels() {
    if (this.isInteractive) {
      this.ADD_CONTENT = 'ADD_BACKGROUND';
      this.DELETE_SEQUENCE = 'DELETE_SEQUENCE_INTERACTIVE';
      this.DELETE_SEQUENCE_PROMPT = 'DELETE_SEQUENCE_INTERACTIVE_PROMPT';
      this.DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_INTERACTIVE_SUCCESS';
    }
  }

  async initSubscriptions() {
    this.subs.sink =
      this.playlistEditorService.editingSequencesChange.subscribe(() => {
        this.setValues();
      });

    this.subs.sink =
      this.playlistEditorService.duplicateStartTimeAssets.subscribe(
        (assets: Asset[]) => {
          this.setDuplicateStartTimeStatus(assets);
        },
      );

    this.playlistEditorService.editingAssetItemsChange.subscribe(
      (assetItems: AssetItem[]) => {
        this.assetItems = assetItems;
      },
    );
  }

  initValues() {
    this.popLicensed = this.currentUserService.isFeatureEnabled(
      CustomFeatures.ProofOfPlay,
    );

    this.totalDuration = 0;

    this.defaultScheduleDays = {
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    };
  }

  /** set ui form values based on this.asset */
  setValues() {
    this.totalDuration = this.asset().content?.reduce<number>(
      (accumulator, contentItem) => {
        if (contentItem.duration) {
          accumulator += contentItem.duration;
          if (contentItem.__typename === 'ImageAsset') {
            accumulator += contentItem.transitionEffect?.duration || 0;
          }
        }

        return accumulator;
      },
      0,
    );

    if (
      this.asset &&
      this.asset().actualStartTime &&
      this.asset().actualEndTime
    ) {
      this.startTimeInputValue =
        this.asset().actualStartTime?.substring(0, 5) || '';
      const localizedStartTime = this.asset().actualStartTime?.substring(0, 5);
      const localizedEndTime = this.asset().actualEndTime?.substring(0, 5);

      if (localizedStartTime) {
        // console.log('localizedStartTime', localizedStartTime);
        this.startTimeInputValue = moment(
          getDateTime(localizedStartTime),
        ).format('HH:mm');
      }
    } else {
      this.startTimeInputValue = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('HH:mm');
    }

    this.setDuplicateStartTimeStatus(
      this.playlistEditorService.duplicateStartTimeAssetsValue,
    );
  }

  setDuplicateStartTimeStatus(assets: Asset[]) {
    if (assets.some((x) => x.id === this.asset().id)) {
      this.isDuplicateStartTime = true;
    } else {
      this.isDuplicateStartTime = false;
    }
  }

  onStartTimeInputClose() {
    this.isStartTimeInputOpen = false;
    const validStartTimeFormat = moment(
      this.startTimeInputValue,
      'HH:mm',
      true,
    ).isValid();

    let actualStartTime: Maybe<string>;
    if (validStartTimeFormat) {
      const startTimeMoment = moment(this.startTimeInputValue, 'HH:mm');

      // state.startTime = getISOTime(startTimeMoment.seconds(0));
      actualStartTime = getISOTimeNoTz(startTimeMoment.seconds(0));
    } else {
      actualStartTime = null;
    }

    this.playlistStore.setDayPartStartTime(
      this.editingAssetIdx,
      actualStartTime || '00:00:00',
    );
  }

  setDaypartName(event: Event) {
    if (!this.canEdit) return;
    const newName = (event.target as HTMLInputElement).value;

    this.playlistStore.setSequenceName(this.editingAssetIdx, newName);

    this.isEditingName = false;
  }

  /** open a popup dialog to add image or video */
  addContent() {
    if (!this.canEdit) return;

    this.playlistEditorService.closePickers.emit();

    const modalRef = this.modalService.open(AddAssetContentDialogComponent, {
      windowClass: 'cesdk-modal',
      backdrop: 'static',
    });
    const component = modalRef.componentInstance;
    component.profileId = this.profileId;
    component.multiSelect = !this.isInteractive;
    modalRef.result.then(
      async (value) => {
        if ('mediaList' in value && value.mediaList?.length) {
          console.log('mediaList', value.mediaList);
          for (const media of value.mediaList) {
            await this.doAddContent(media);
          }
        } else {
          await this.doAddContent(value);
        }
      },
      () => {},
    );
  }

  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  async addIframe() {
    if (!this.canEdit) return;

    const modalRef = this.modalService.open(IframeEditorComponent, {
      windowClass: 'cesdk-modal',
      // size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.assetId;
    modalRef.componentInstance.assetItemId;

    modalRef.componentInstance.htmlObject.subscribe(
      (iframeContent: IFrameAsset) => {
        this.doAddContent(iframeContent);
      },
    );
  }

  async doAddContent(content: AssetItem, insertIndex?: number) {
    const id = getRandomString(); // uuidV4();
    content.id = id;
    if (this.isInteractive && AssetItemIsImageOrVideo(content)) {
      await this.setAssetItemDimensionsFromMedia(
        AssetItemToImageOrVideo(content),
      );
    }
    await this.playlistStore.addAssetItem(
      this.editingAssetIdx,
      insertIndex,
      content,
    );
  }

  async setAssetItemDimensionsFromMedia(
    content: ImageAsset | VideoAsset | undefined,
  ) {
    if (content?.contentId) {
      const media = content.media?.metadata?.width
        ? content.media
        : await this.mediaService.getMediaById(content.contentId);
      if (media) {
        content.height = media.metadata?.height;
        content.width = media.metadata?.width;
      }
    }

    return content;
  }

  /** removes a SEQUENCE */
  async removeAsset() {
    if (!this.canEdit) return;

    const response = await this.popupService.confirm(
      'DELETE_CONFIRMATION',
      this.asset().name,
    );

    if (response === ConfirmationResponse.Yes) {
      this.playlistStore.deleteSequence(this.editingAssetIdx);
    }
  }

  // media or interactive layout
  editContent(params: {
    contentItem: AssetItem;
    itemIdx: number;
    editActions: boolean;
  }) {
    if (!this.canEdit) return;

    const { contentItem, itemIdx, editActions } = params;

    if (this.asset().content) {
      // Target Content to update
      const contentIndexToUpdate = itemIdx;
      switch (contentItem.__typename) {
        case 'ImageAsset':
          if (editActions) {
            this.editInteractiveLayout(contentItem, itemIdx);
          } else {
            this.editImageOrVideo(
              MediaSourceTypes.PLAYLIST_IMAGE,
              contentItem,
              contentIndexToUpdate,
            );
          }
          break;
        case 'VideoAsset':
          if (editActions) {
            this.editInteractiveLayout(contentItem, itemIdx);
          } else {
            this.editImageOrVideo(
              MediaSourceTypes.PLAYLIST_VIDEO,
              contentItem,
              contentIndexToUpdate,
            );
          }
          break;
        case 'IFrameAsset':
          this.editIFrameAsset(contentItem, contentIndexToUpdate);
          break;

        default:
          break;
      }
    }
  }

  replaceContent(contentIndexToUpdate: number) {
    if (!this.canEdit) return;

    if (this.asset().content) {
      // Target Content to update

      // TODO: refactory this?
      this.playlistEditorService.closePickers.emit();

      const modalRef = this.modalService.open(EditAssetContentDialogComponent, {
        size: 'xl',
        backdrop: 'static',
      });

      modalRef.componentInstance.profileId = this.profileId;
      modalRef.componentInstance.multiSelect = false;
      modalRef.result.then(
        (newContent: AssetItem) => {
          this.playlistStore.setAssetItemContent(
            this.editingAssetIdx,
            contentIndexToUpdate,
            newContent,
          );
        },
        () => {},
      );
    }
  }

  /** wtf rename this! */
  emitSelectedAssets() {
    this.playlistEditorService.sequenceTouch.emit(true);
    this.playlistEditorService.emitEditingSequencesChange();
  }

  editName(e: Event) {
    if (!this.canEdit) return;

    e.stopPropagation();
    e.preventDefault();
    this.isEditingName = true;
    setTimeout(() => {
      this.nameInput.nativeElement.select();
    });
  }

  async drop(event: CdkDragDrop<AssetItem[]>) {
    if (!this.canEdit) return;

    if (this.isInteractive) {
      return;
    }

    console.log('drop', event);

    if (
      event.previousContainer.id === 'media-gallery' ||
      event.previousContainer.id === 'media-list'
    ) {
      // this is the dropped item
      const content = event.item.data;

      // TODO: the content type (AssetItem, AssetContent, AssetInput, Media etc.) should be one type
      // atm the type of content in media-gallery is not the same
      // as the content in asset-row, in my opinion they should have
      // consistent types throughout the codebase so we don't have
      // to make unnecessary transformations/mutations
      const transformedContent =
        this.playlistEditorService.toAssetItem(content);
      if (transformedContent) {
        const insertIndex = event.currentIndex;
        await this.doAddContent(transformedContent, insertIndex);
      }
    } else {
      console.log('move item');
      const oldAssetIdx = this.playlistStore.playlist
        .assets()
        .findIndex((x) => x.id === event.previousContainer.id);
      const newAssetIdx = this.playlistStore.playlist
        .assets()
        .findIndex((x) => x.id === event.container.id);
      this.playlistStore.moveAssetItem(
        oldAssetIdx,
        event.previousIndex,
        newAssetIdx,
        event.currentIndex,
      );
    }

    dragDropCursorUtil.cursorReset();
  }

  /** edit with creative editor */
  editImageOrVideo(
    mediaType: MediaSourceTypes,
    oldContent: AssetItem,
    itemIdx: number,
  ) {
    if (!this.canEdit) return;

    const editingItem = oldContent as ImageAsset;
    const videoEditingItem = oldContent as VideoAsset;

    const modalRef = this.modalService.open(CreativeEditorComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });
    modalRef.componentInstance.calledFrom = CeCalledFrom.PLAYLIST;
    modalRef.componentInstance.mediaId =
      mediaType === MediaSourceTypes.IMAGE
        ? editingItem.media?.id
        : videoEditingItem.media?.id;
    // following if block should be moved in CreativeEditor component
    if (
      mediaType === (MediaSourceTypes.PLAYLIST_IMAGE || MediaSourceTypes.IMAGE)
    ) {
      modalRef.componentInstance.initialSceneMode = 'Design';
      modalRef.componentInstance.inputImgUrl = editingItem.uri;
    } else if (
      mediaType === (MediaSourceTypes.PLAYLIST_VIDEO || MediaSourceTypes.VIDEO)
    ) {
      modalRef.componentInstance.initialSceneMode = 'Video';
      modalRef.componentInstance.inputVideoUrl = videoEditingItem.uri;
    }
    // Creative Editor should access arcService instead
    modalRef.componentInstance.previewResolution =
      this.arcService.getPlaylistAspect();
    // modalRef.dismiss();
    modalRef.result.then(
      (newMedia?: Media) => {
        if (newMedia) {
          // console.log('oldContent', oldContent);
          // console.log('substitute media in asset item', newMedia);

          this.playlistStore.setAssetItemMedia(
            this.editingAssetIdx,
            itemIdx,
            newMedia,
          );

          // refresh media-gallery
          this.mediaService.refetchMedia();
        }
      },
      () => {},
    );
  }

  enteredDroplist(event: CdkDragEnter) {
    // console.log('entered droplist id in asset-row', event.container.id);
    this.isDroplistEntered.set(true);
  }
  exitedDroplist(event: CdkDragExit) {
    // console.log('entered droplist id in asset-row', event.container.id);
    this.isDroplistEntered.set(false);
  }

  /** every asset list can have max 1 interactive page background (image or video) */
  getInteractivePageBgItem() {
    for (const item of this.asset().content) {
      if (AssetItemIsImage(item)) {
        const imageAsset = item as ImageAsset;
        if (
          imageAsset.interactiveRegions &&
          imageAsset.interactiveRegions.length > 0
        ) {
          return imageAsset;
        }
      }
      if (AssetItemIsVideo(item)) {
        const videoAsset = item as VideoAsset;
        if (
          videoAsset.interactiveRegions &&
          videoAsset.interactiveRegions.length > 0
        ) {
          return videoAsset;
        }
      }
      return undefined;
    }
  }
  /** when a virtual asset item is substituted or modified its new contentId is synced with its interactive action (to be reevaluated in the future) */
  reLinkActionToTarget(
    oldContentId: Maybe<string>,
    newContentId: Maybe<string>,
  ) {
    const interactivePage = this.getInteractivePageBgItem();
    const touchRegions = interactivePage?.interactiveRegions;
    touchRegions?.forEach((region) => {
      if (region?.action?.target && region?.action?.target === oldContentId)
        [(region.action.target = newContentId)];
    });
  }
  setAssetItemMedia(contentItem: AssetItem, media: Media) {
    contentItem.name = media.name;
    contentItem.contentId = media.id;
    if (media.type === ResourceType.Video) {
      if (media.metadata?.duration)
        (contentItem as VideoAsset).duration = media.metadata.duration * 1000;
      if (media.metadata?.width)
        (contentItem as VideoAsset).width = media.metadata.width;
      if (media.metadata?.height)
        (contentItem as VideoAsset).height = media.metadata.height;
      (contentItem as VideoAsset).media = media;
      (contentItem as VideoAsset).uri = media.secureUrl;
    } else {
      (contentItem as ImageAsset).media = media;
      (contentItem as ImageAsset).uri = media.secureUrl;
    }
  }

  // Signal redo: this will remains as it is
  editInteractiveLayout(oldContent: AssetItem, editingItemIdx: number) {
    const imageOrVideoItem = AssetItemToImageOrVideo(oldContent);
    if (!imageOrVideoItem) return;

    const modalRef = this.modalService.open(
      InteractiveLayoutManageDialogComponent,
      {
        backdrop: 'static',
        windowClass: 'cesdk-modal',
        keyboard: false,
      },
    );

    modalRef.componentInstance.source =
      imageOrVideoItem.interactiveLayoutSource || '';
    modalRef.componentInstance.interactiveRegions =
      imageOrVideoItem.interactiveRegions || [];
    modalRef.componentInstance.height = imageOrVideoItem.height;
    modalRef.componentInstance.width = imageOrVideoItem.width;

    if (AssetItemIsImage(imageOrVideoItem)) {
      modalRef.componentInstance.backgroundUrl = imageOrVideoItem.uri;
    } else if (AssetItemIsVideo(imageOrVideoItem)) {
      modalRef.componentInstance.backgroundUrl = `${imageOrVideoItem.uri?.substring(
        0,
        imageOrVideoItem.uri.lastIndexOf('.'),
      )}.png`;
    }

    modalRef.result.then(
      async (response?: IInteractiveLayoutModalResponse) => {
        if (response) {
          if (imageOrVideoItem) {
            await this.playlistStore.setInteractiveItem(
              this.editingAssetIdx,
              editingItemIdx,
              response.interactiveRegions,
              response.source,
              response.width,
              response.height,
            );
          }

          this.emitSelectedAssets();
        }
      },
      () => {},
    );
  }

  MediaToVideoAsset(video: MediaDetailedFragment) {
    const { id, name, publicId } = video;
    const d = video?.metadata?.duration || 0;
    const duration = Math.round(d * 1000);

    const videoAsset: VideoAsset = {
      name,
      contentId: id,
      duration,
      type: AssetType.Video,
      __typename: 'VideoAsset',
      uri: video.secureUrl,
      publicId,
    };
    return videoAsset;
  }

  editIFrameAsset(oldContent: AssetItem, index: number) {
    if (!this.canEdit) return;

    const editingItem = oldContent as IFrameAsset;

    const modalRef = this.modalService.open(IframeEditorComponent, {
      windowClass: 'cesdk-modal',
      // size: 'xl',
      backdrop: 'static',
    });
    // TODO add playlist width and height
    modalRef.componentInstance.iframeUrl = editingItem.uri;
    modalRef.componentInstance.assetTitle = editingItem.name;
    modalRef.componentInstance.assetId;
    modalRef.componentInstance.assetItemId;

    modalRef.componentInstance.htmlObject.subscribe(
      (iframeContent: IFrameAsset) => {
        this.playlistStore.setAssetItemContent(
          this.editingAssetIdx,
          index,
          iframeContent,
        );
      },
    );

    modalRef.result.then(() => {
      // refresh media-gallery
      this.mediaService.refetchMedia();
    });
  }
}
