<div
  ngbAccordion
  [destroyOnHide]="false"
  class="accordion-custom"
  [class.hide-caret]="assetsCount() <= 1"
>
  <!-- (panelChange)="panelChange($event)" -->
  <div
    [ngbAccordionItem]="asset().id"
    [collapsed]="false"
    class="overflow-hidden"
  >
    <!-- [disabled]="assetsCount < 1 ? true : false" -->
    <button ngbAccordionButton class="accordion-button">
      <div class="w-100 d-flex align-items-center">
        <!-- Day part scheduled indicator (green or yellow) -->
        @if (!isInteractive && assetsCount() > 1) {
          <span
            [ngStyle]="activityStatus().style"
            [ngbTooltip]="activityStatus().status"
            class="col-1 status-indicator"
          ></span>

          <!-- Day Part name -->
          <div class="col-auto px-2" [class.input-group]="isEditingName">
            <!-- Name when not in edit mode -->
            <div class="sequence-name icon-appear-on-hover">
              @if (!isEditingName) {
                <h5
                  class="text text-truncate"
                  [ngbTooltip]="asset().name"
                  (click)="!readOnly ? editName($event) : null"
                >
                  {{ asset().name }}
                  @if (!readOnly) {
                    <span class="icon">
                      <i class="ri-pencil-fill text-primary"></i>
                    </span>
                  }
                </h5>
              }
            </div>
            <!-- Name when editing it -->
            @if (isEditingName) {
              <input
                autocomplete="off"
                #nameInput
                (click)="$event.stopPropagation()"
                class="form-control form-control-dark w-100"
                [value]="asset().name"
                (blur)="setDaypartName($event)"
                (keydown.enter)="setDaypartName($event)"
                (keyup)="$event.preventDefault()"
              />
            }
          </div>

          <!-- Day Part start and end time -->
          <div
            class="col-2 d-flex align-items-baseline"
            ngbTooltip="{{ 'DAYPART_START_TT' | translate }}"
            placement="top"
          >
            <div
              class="d-none d-xl-block me-2 text-truncate"
              [class.start-label]="!readOnly"
            >
              {{ 'START' | translate }}:
            </div>
            @if (!readOnly) {
              <designage-timepicker
                [(time)]="startTimeInputValue"
                [showSecond]="false"
                (click)="$event.stopPropagation()"
                (timeChange)="onStartTimeInputClose()"
              />
            } @else {
              {{ startTime() | date: 'shortTime' }}
            }
            @if (playlistStore.isDuplicateStartTime()) {
              <div class="badge bg-danger duplicate-error">
                {{ 'SEQUENCE_START_DUPLICATE_ERROR' | translate }}
              </div>
            }
            @if (!asset().actualStartTime) {
              <div class="badge bg-danger duplicate-error">
                {{ 'START_TIME_FORMAT_ERROR' | translate }}
              </div>
            }
          </div>

          <!-- Day Part run time -->
          @if (asset().actualStartTime && asset().actualEndTime) {
            <div
              class="col-3 d-flex"
              ngbTooltip="{{ 'RUNTIME_TT' | translate }}"
            >
              <span class="d-none d-xl-block me-2 text-truncate">
                {{ 'RUNTIME' | translate }}:
              </span>
              <span>
                {{ startTime() | date: 'shortTime' }} -
                {{ endTimeView() | date: 'shortTime' }}
              </span>
            </div>
          }
        }
        <!-- Day Part Duration -->
        @if (!isInteractive) {
          <div class="col d-flex justify-content-center">
            <span class="d-none d-xl-block me-2 text-truncate">
              {{
                assetsCount() > 1
                  ? ('DAY_PART' | translate)
                  : ('PLAYLIST' | translate)
              }}
              {{ 'DURATION' | translate }}:</span
            >
            <span>{{ totalDuration | duration }}</span>
          </div>
        }
      </div>
    </button>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-container *ngTemplateOutlet="contentRow"></ng-container>
        @if (!readOnly) {
          <div class="content-footer d-flex">
            <div class="flex-grow-1">
              <button
                type="button"
                class="btn btn-outline-primary border border-primary me-2"
                (click)="addContent(); $event.stopPropagation()"
              >
                {{ ADD_CONTENT | translate }}
              </button>
              @if (!simpleUiActive) {
                <div ngbDropdown container="body" class="d-inline-block">
                  @if (!isInteractive) {
                    <button
                      type="button"
                      class="btn btn-outline-primary border border-primary me-2"
                      id="widgetDropdown"
                      ngbDropdownToggle
                    >
                      {{ 'ADD_WIDGET' | translate }}
                    </button>
                  }
                  <div
                    ngbDropdownMenu
                    class="form-control form-control-dark shadowed-box text-white"
                    aria-labelledby="widgetDropdown"
                  >
                    @if (isUserSuperAdmin && !isInteractive) {
                      <button
                        type="button"
                        ngbDropdownItem
                        ngbTooltip="{{ 'ADD_IFRAME_TT' | translate }}"
                        (click)="addIframe(); $event.stopPropagation()"
                      >
                        {{ 'ADD_IFRAME' | translate }}
                      </button>
                    }
                  </div>
                </div>
              }
              <button
                type="button"
                class="btn delete-btn btn-outline-danger border border-danger me-2"
                (click)="removeAsset(); $event.stopPropagation()"
              >
                {{ DELETE_SEQUENCE | translate }}
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #contentRow>
  <div
    style="min-height: 2rem"
    [id]="sequenceId || 'sequenceId'"
    [class.dropzone-entered]="isDroplistEntered()"
    cdkDropList
    [cdkDropListData]="asset().content"
    [cdkDropListSortingDisabled]="readOnly"
    [cdkDropListConnectedTo]="assetIds()"
    (cdkDropListDropped)="drop($event)"
    (cdkDropListEntered)="enteredDroplist($event)"
    (cdkDropListExited)="exitedDroplist($event)"
  >
    <!-- note: this br element was added for the drag and drop, this is to
      increase the container's dropzone height when empty since it is possible 
      to have a dropzone height that is so thin (probably like 1px) that the 
      user cannot drop anything on the container 
    <br />-->
    @if (asset().content.length === 0) {
      <h5 class="text-center">
        {{ 'EMPTY_DAYPART' | translate }}
      </h5>
    }
    @for (content of asset().content; track content.id; let i = $index) {
      <div
        class="draggable position-relative"
        cdkDrag
        [cdkDragData]="content"
        [cdkDragDisabled]="readOnly"
        cdkDragLockAxis="y"
      >
        @if (content && content.id) {
          <app-content-item
            [popLicensed]="popLicensed"
            [readOnly]="readOnly"
            [assetActivityStatus]="activityStatus()"
            [assetId]="asset().id"
            [contentItem]="content"
            [editingAssetIdx]="editingAssetIdx"
            [editingItemIdx]="i"
            [simulateDateTime]="simulateDateTime()"
            (replaceContent)="replaceContent($event)"
            (editContent)="editContent($event)"
            [simpleUiActive]="simpleUiActive"
          />
        }
        @if (asset().content.length > 1) {
          <hr class="content-divider" />
        }
      </div>
    }
  </div>
</ng-template>
