import {
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
  ElementRef,
  OnInit,
  Signal,
  ChangeDetectionStrategy,
  inject,
  computed,
  effect,
  viewChild,
  input,
  signal,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  Maybe,
  UpdateDeviceGQL,
  ChannelBonsaiFragment,
  DeviceInfo,
  UpdateDeviceInput,
  ProfileLocationsFragment,
  ScheduleDaysInput,
} from '@designage/gql';
import {
  DeviceDataService,
  CurrentUserService,
  ToasterService,
  UiDataService,
  ProfileSettingsService,
  ResponsiveUiService,
  ChannelService,
  SlidePanelService,
} from '@desquare/services';
import {
  NgbDropdownModule,
  NgbModal,
  NgbNavModule,
  NgbTimepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { DeviceRemoveDialogComponent } from '../device-remove-dialog/device-remove-dialog.component';
import { ILocationForm, IScreenshotContext } from '@desquare/interfaces';
import mapboxgl from 'mapbox-gl';
import { DeviceStatusInfo } from '@desquare/models';
import { LocationFormComponent } from '@designage/app/location/location-form/location-form.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ngbTimeStructUtil } from '@desquare/utils';
import { NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';
import { DeviceScreenshotComponent } from '../device-screenshot/device-screenshot.component';
import { DeviceDetailsTableComponent } from '@desquare/components/common/src/device-details-table/device-details-table.component';
import { LocationSelectionComponent } from '@designage/app/location/location-selection/location-selection.component';
import { DeviceMonitorComponent } from '../device-monitor/device-monitor.component';
import { EventListComponent } from '@designage/app/channel/event-list/event-list.component';
import { DeviceLogComponent } from '@desquare/components/common/src/device-log/device-log.component';
import { DevicesStore } from '@desquare/stores';
import { DeviceVolumeComponent } from '@desquare/components/common/src/device-ui-components/device-volume.component';
import { DeviceBrightnessComponent } from '@desquare/components/common/src/device-ui-components/device-brightness.component';
import { IPowerControls } from './device-manage.model';
import { DeviceData } from '@desquare/types';
import { DeviceManageSettingsComponent } from './device-manage-settings/device-manage-settings.component';
import { lastValueFrom } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { cloneDeep } from 'lodash';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DesButtonComponent } from '@desquare/ui-components/des-button.component';

@Component({
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormsModule,
    TranslateModule,
    AngularSplitModule,
    NgbNavModule,
    NgbDropdownModule,
    DeviceScreenshotComponent,
    DeviceDetailsTableComponent,
    LocationFormComponent,
    LocationSelectionComponent,
    DeviceMonitorComponent,
    EventListComponent,
    DeviceLogComponent,
    NgxSliderModule,
    DeviceVolumeComponent,
    DeviceBrightnessComponent,
    DeviceManageSettingsComponent,
    LocationSelectionComponent,
    NgxSkeletonLoaderModule,
    DesButtonComponent,
  ],
  selector: 'app-device-manage',
  templateUrl: './device-manage.component.html',
  styleUrls: ['./device-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('StatusGaugeAnimation', [
      transition(':enter', [
        style({ margin: '0 -160px 0 0', opacity: '0' }),
        animate('0.25s ease-out', style({ margin: '*', opacity: '1' })),
      ]),
      transition(':leave', [
        style({ margin: '*', opacity: '1' }),
        animate(
          '0.25s ease-in',
          style({ margin: '0 -160px 0 0', opacity: '0' }),
        ),
      ]),
    ]),
    trigger('showHidePlaylistLoader', [
      transition(':enter', [
        style({ height: '0' }),
        animate('0.25s ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('0.2s ease-in', style({ height: '0' })),
      ]),
    ]),
  ],
})
export class DeviceManageComponent implements OnInit, OnDestroy {
  devicesStore = inject(DevicesStore);
  currentUserService = inject(CurrentUserService);
  slidePanelService = inject(SlidePanelService);

  device = input.required<DeviceData>();
  showCloseButton = input<boolean>();
  channelParent = input<boolean>();

  locationComponent = viewChild<LocationFormComponent>('locationComponent');
  image = viewChild<ElementRef>('image');
  saving = signal<boolean>(false);

  @Output() closeClick = new EventEmitter();

  private subs = new SubSink();

  onErrorMessage!: string;
  powerActionLoader = false;
  isMapReady = false;
  loadingScreenshot = false;
  loaderMessage = '';
  deletingDevice = signal(false);

  newTimeZone = '';
  newNtpServer = '';
  noMatchedItemText = '';

  selectedChannel: Maybe<ChannelBonsaiFragment>;
  profileId = signal<string>(this.currentUserService.getCurrentProfileId());
  deviceId = computed<string>(() => this.device().id);

  locationForm = computed(() =>
    this.editLocation()
      ? (this.vm.device.location as ILocationForm)
      : (this.device().location as ILocationForm),
  );

  protected readonly deviceSettings = signal<DeviceData>({
    updatedAt: undefined,
    createdAt: undefined,
    id: '',
    name: '',
  });

  protected readonly viewModel = computed(() => ({
    device: this.deviceSettings(),
  }));
  protected get vm() {
    return this.viewModel();
  }

  /** true when edit form is active */
  isEditingSettings = signal<boolean>(false);
  /** true while updating settings */
  settingsLoading = false;

  screenshotContext!: IScreenshotContext;
  map!: mapboxgl.Map;
  marker = new mapboxgl.Marker({
    color: '#FFFFFF',
    draggable: false,
  });

  editLocation = signal<boolean>(false);
  /** DANGEROUS: this shows a button that can delete device from db without removing in SOS */
  showForceDeprovision = false;
  showDeviceLog = false;

  ngbTimeStructUtil = ngbTimeStructUtil;

  get appletVersionInfo() {
    const version =
      this.device()?.advancedSettings?.appletVersion ||
      this.device()?.deviceInfo?.appletVersion;
    const versionParts = version?.split('.');
    const major = versionParts?.shift() || '0';
    const minor = versionParts?.shift() || '0';

    return { major: +major, minor: +minor };
  }

  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  get showEvents() {
    return this.currentUserService.canManageEvents;
  }

  deviceStatusSignal?: Signal<DeviceStatusInfo>;
  deviceInfoSignal?: Signal<Maybe<DeviceInfo>>;

  constructor(
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private deviceDataService: DeviceDataService,
    private updateDevice: UpdateDeviceGQL,
    private uiDataService: UiDataService,
    public timepickerConfig: NgbTimepickerConfig,
    public channelService: ChannelService,
    public profileSettingsService: ProfileSettingsService,
    public responsiveUiService: ResponsiveUiService,
  ) {
    timepickerConfig.minuteStep = 15;
    timepickerConfig.size = 'small';
    timepickerConfig.seconds = false;
    // effect(() => {
    //   console.log('device', this.device()); //DEBUG
    // });
  }

  loading = computed(() => this.devicesStore.loading());
  isDeviceOnline = computed(
    () => this.device().status === DeviceStatusInfo.Online,
  );
  profileChannels = signal<{ id: string; name: string }[]>([]);
  powerControls = signal<IPowerControls>({});

  ngOnInit() {
    this.channelService.getCurrentProfileChannels().then((channels) => {
      const updatedChannels = channels.map((x) => ({
        id: x?.id || '',
        name: x?.name || '',
      }));
      if (updatedChannels.length === 0) return;
      this.profileChannels.set([...updatedChannels]);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onEditLocation() {
    this.deviceSettings.set(cloneDeep(this.device()));
    this.editLocation.set(true);
  }

  setLocation(newLocation: ProfileLocationsFragment) {
    this.deviceSettings.update((state) => ({
      ...state,
      location: cloneDeep(newLocation),
    }));
  }

  /**
   * DANGEROUS, normally don't set force to true
   * @param force if true deletes only from db, can loose connection with SOS
   */
  deprovisionDevice(force: boolean = false) {
    // TODO: use force flag to change the message in the dialog
    this.modalService
      .open(DeviceRemoveDialogComponent)
      .result.then(async () => {
        if (this.device()?.id) {
          let success = false;
          this.deletingDevice.set(true);
          if (force && this.isUserSuperAdmin) {
            success = await this.deviceDataService.forceDeprovisionDevice(
              this.device()!.id,
            );
          } else {
            success = await this.deviceDataService.deprovisionDevice(
              this.device()!.id,
            );
            if (!success && this.isUserSuperAdmin) {
              this.showForceDeprovision = true;
            }
          }
          this.deletingDevice.set(false);
          if (success) {
            this.closeClick.emit();
          }
          // this.loading = false;
        }
      })
      .catch(() => {
        // do nothing on cancel
      });
  }

  async saveForm(
    deviceData: DeviceData,
    section: 'power' | 'settings' | 'location',
  ) {
    // console.log('saveForm', section, deviceData); // DEBUG
    this.saving.set(true);
    const input: UpdateDeviceInput = {
      id: deviceData.id,
      name: section === 'settings' ? deviceData.name : this.device().name,
      channelId:
        section === 'settings' ? deviceData.channelId : this.device().channelId,
      timeZone:
        section === 'settings'
          ? deviceData.advancedSettings?.timeZone
          : this.device().advancedSettings?.timeZone,
      ntpServer:
        section === 'settings'
          ? deviceData.advancedSettings?.ntpServer
          : this.device().advancedSettings?.ntpServer,
      orientation:
        section === 'settings'
          ? deviceData.advancedSettings?.orientation
          : this.device().advancedSettings?.orientation,
      width: section === 'settings' ? deviceData.width : this.device().width,
      height: section === 'settings' ? deviceData.height : this.device().height,
      advancedSettings: {
        popEnabled:
          section === 'settings'
            ? (deviceData.advancedSettings?.popEnabled ?? false)
            : (this.device().advancedSettings?.popEnabled ?? false),
        flipScreenshot:
          section === 'settings'
            ? (deviceData.advancedSettings?.flipScreenshot ?? false)
            : this.device().advancedSettings?.flipScreenshot,
        synchronize:
          section === 'settings'
            ? (deviceData.advancedSettings?.synchronize ?? false)
            : (this.device().advancedSettings?.synchronize ?? false),
        manualSyncGroup:
          section === 'settings'
            ? (deviceData.advancedSettings?.manualSyncGroup ?? false)
            : (this.device().advancedSettings?.manualSyncGroup ?? false),
        syncGroup:
          section === 'settings'
            ? (deviceData.advancedSettings?.syncGroup ?? '')
            : (this.device().advancedSettings?.syncGroup ?? ''),
        masterSyncDelay:
          section === 'settings'
            ? (deviceData.advancedSettings?.masterSyncDelay ?? 0)
            : (this.device().advancedSettings?.masterSyncDelay ?? 0),
        scheduleDailyReboot:
          section === 'settings'
            ? (deviceData.advancedSettings?.scheduleDailyReboot ?? false)
            : (this.device().advancedSettings?.scheduleDailyReboot ?? false),
        rebootTime:
          section === 'settings'
            ? (deviceData.advancedSettings?.rebootTime ?? '00:00:00')
            : (this.device().advancedSettings?.rebootTime ?? '00:00:00'),
        kioskMode:
          section === 'settings'
            ? (deviceData.advancedSettings?.kioskMode ?? false)
            : (this.device().advancedSettings?.kioskMode ?? false),
        kioskModeGraceSecs:
          section === 'settings'
            ? (deviceData.advancedSettings?.kioskModeGraceSecs ?? 0)
            : (this.device().advancedSettings?.kioskModeGraceSecs ?? 0),
        scheduleDisplay:
          section === 'settings'
            ? deviceData.advancedSettings?.scheduleDisplay?.map((schedules) => {
                return {
                  on: schedules.on,
                  off: schedules.off,
                  days: {
                    monday: schedules.days.monday,
                    tuesday: schedules.days.tuesday,
                    wednesday: schedules.days.wednesday,
                    thursday: schedules.days.thursday,
                    friday: schedules.days.friday,
                    saturday: schedules.days.saturday,
                    sunday: schedules.days.sunday,
                  } as ScheduleDaysInput,
                };
              })
            : [
                ...(this.device().advancedSettings?.scheduleDisplay?.map(
                  ({ __typename, days, ...rest }) => ({
                    ...rest,
                    days: {
                      monday: days.monday,
                      tuesday: days.tuesday,
                      wednesday: days.wednesday,
                      thursday: days.thursday,
                      friday: days.friday,
                      saturday: days.saturday,
                      sunday: days.sunday,
                    } as ScheduleDaysInput,
                  }),
                ) || []),
              ],
        scheduleBrightness: {
          brightness1:
            section === 'settings'
              ? (deviceData.advancedSettings?.scheduleBrightness?.brightness1 ??
                100)
              : (this.device().advancedSettings?.scheduleBrightness
                  ?.brightness1 ?? 100),
          brightness2:
            section === 'settings'
              ? (deviceData.advancedSettings?.scheduleBrightness?.brightness2 ??
                100)
              : (this.device().advancedSettings?.scheduleBrightness
                  ?.brightness2 ?? 100),
          time1:
            section === 'settings'
              ? (deviceData.advancedSettings?.scheduleBrightness?.time1 ??
                '00:00:00')
              : (this.device().advancedSettings?.scheduleBrightness?.time1 ??
                '00:00:00'),
          time2:
            section === 'settings'
              ? (deviceData.advancedSettings?.scheduleBrightness?.time2 ??
                '00:00:00')
              : (this.device().advancedSettings?.scheduleBrightness?.time2 ??
                '00:00:00'),
        },
        volume:
          section === 'settings'
            ? (deviceData.advancedSettings?.volume ?? 0)
            : (this.device().advancedSettings?.volume ?? 0),
      },
      locationId:
        section === 'location'
          ? deviceData.location?.id
          : this.device().location?.id,
      coordinates: {
        x: this.device().coordinates?.x ?? 0,
        y: this.device().coordinates?.y ?? 0,
      },
    };
    // console.log('input', input); // DEBUG

    await lastValueFrom(this.updateDevice.mutate({ input }))
      .then(({ data, loading }) => {
        if (
          data &&
          data.updateDevice &&
          data.updateDevice.isSuccessful &&
          data.updateDevice.device
        ) {
          // console.log('device updated', data.updateDevice.device);
          this.devicesStore.updateDeviceSettings(data.updateDevice.device);
          this.channelService.refreshChannels();
          this.toasterService.success(
            'UPDATE_DEVICE_SUCCESSFUL',
            data.updateDevice.device?.name,
          );
        } else {
          this.toasterService.error('UPDATE_DEVICE_ERROR');
        }
      })
      .catch((error) => {
        this.toasterService.error('UPDATE_DEVICE_ERROR');
        console.error('update device error', error);
      });
    this.saving.set(false);
    this.editLocation.set(false);
  }

  openModal(template: TemplateRef<any>) {
    this.modalService.open(template, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
  }

  updateView() {
    this.uiDataService.updateViewMode();
    this.locationComponent()?.updateMap();
  }

  openDeviceLocationMap(deviceLocationMap: any) {
    const modalRef = this.modalService.open(deviceLocationMap, {
      size: 'lg',
    });
    modalRef.componentInstance.hidden = this.loading();
    modalRef.componentInstance.showMapOnly = true;
    modalRef.componentInstance.location = this.device()?.location;
  }

  saveSelectedLocation(e: Event) {
    window.alert('Marco, this should save in DB!');
  }

  createLocation(e: string) {
    console.log('createLocation', e);
  }
}
